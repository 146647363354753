import React, {useEffect, useMemo} from 'react';
import Select, {OptionType} from "@atlaskit/select";
import Spinner from "@atlaskit/spinner";
import {useSelector} from "react-redux";
import PostAdd from "@material-ui/icons/PostAdd";

import {userSelector} from "../../../../redux/login/selectors";
import useProjectsQuery from "../../../../api/useProjectsQuery";
import Button from "@atlaskit/button";

interface Props {
    siteId?: string;
    siteUrl?: string;
    onChange: (option: OptionType) => void,
    value: OptionType | null
}

const ProjectSelect: React.FC<Props> = ({ onChange, value, siteId, siteUrl }) => {
    const user = useSelector(userSelector);
    const uid = user?.uid;
    const {isLoading, data: projects} = useProjectsQuery(uid, siteId);

    useEffect(() => {
        if (projects && siteId && !value) {
            console.log("setting default project option", projects[0].name);
            onChange({ label: projects[0].name, value: projects[0].id});
        }
    }, [projects, value, onChange, siteId]);

    // useEffect(() => {
    //     // KBM - We have to wait for claims to be available, otherwise Auth could be missing from this request
    //     if (uid) {
    //         console.log("GETTING PROJECTS!");
    //         getJiraProjects().then(projects => {
    //             console.log("projects", projects);
    //             const projectList = (projects.data as any)[0].values;
    //             setProjects(projectList);
    //
    //             // If we don't have a value, go ahead and set one
    //             if (!value) {
    //                 console.log("setting default project option", projectList[0].name);
    //                 onChange({ label: projectList[0].name, value: projectList[0].id});
    //             }
    //         });
    //     }
    // }, [uid, onChange, value]);

    const options = useMemo(() => {
        if (projects) {
            return (projects as any[]).map((project: any) => ({ label: project.name, value: project.key}))
        }
        return [];
    }, [projects]);

    const selectedProject = useMemo(() => (projects as any[])?.find(project => project.key === value?.value), [projects, value]);

    return (
        <div>
            <div className="text-left mb-1">
                <label htmlFor="project" className="font-bold">Project</label>
            </div>
            <div className="flex">
                <div className="flex-1">
                    <Select
                        inputId="project"
                        options={options}
                        value={value}
                        onChange={e => {
                            onChange(e as OptionType);
                        }} />
                </div>
                <div className="w-14">
                    {isLoading ?
                        <Spinner size="medium" />  :
                        value && siteUrl ?
                            <div className="flex ml-2">
                                <Button appearance="subtle" href={`${siteUrl}/secure/CreateIssue.jspa?pid=${selectedProject?.id}`} target="_blank">
                                    <PostAdd />
                                </Button>
                            </div> :
                            null
                    }
                </div>
            </div>
        </div>
    )
};

export default ProjectSelect
