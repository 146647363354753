import { useQuery } from "react-query";

import { getJiraProjects } from "./index";

const useProjectsQuery = (uid: string, siteId: string = "") =>
    useQuery(['projects', uid, siteId], () => {
        return getJiraProjects(siteId).then(results => (results.data as any).values);
    }, {
        enabled: Boolean(uid && siteId)
    });

export default useProjectsQuery;
