import React, { useMemo } from 'react';
import useTodaysWorklogsQuery from "../../../api/useTodaysWorklogsQuery";
import { useSelector } from "react-redux";
import { parse, format } from "date-fns";

import { userSelector } from "../../../redux/login/selectors";
import { DATE_TIME_FORMAT } from "../../../api";

interface Props {
    siteId: string,
}

const TodaysHistory: React.FC<Props> = ({ siteId }) => {
    const user = useSelector(userSelector);
    const uid = user?.uid;
    const {isLoading, data} = useTodaysWorklogsQuery(uid, siteId);

    const formatTime = (date: string) => {
        return format(parse(date, DATE_TIME_FORMAT, new Date()), "hh:mm aa")
    };

    const {minutes, hours} = useMemo(() => {
        const totalSeconds = (data as any[])?.reduce((ret, worklog) => {
            return ret + worklog.timeSpentSeconds;
        }, 0);

        const totalMinutes = Math.ceil(totalSeconds / 60);
        const hours = Math.floor(totalMinutes / 60);

        return {
            minutes: totalMinutes % 60,
            hours
        }
    }, [data]);

    return (
        <div className="mt-8">
            <div className="font-bold text-lg text-left mb-4">
                Today's Worklogs
            </div>
            {isLoading ?
                "Loading..." :
                <div>
                    <div>
                        {(data as any[])?.map((worklog: any) => (
                            <div key={`workilog-${worklog.id}`} className="flex">
                                <div className="w-24 text-left">
                                    {formatTime(worklog.started)}
                                </div>
                                <div className="flex-1 text-left">
                                    {worklog.issue.fields.summary}
                                </div>
                                <div>
                                    {worklog.timeSpent}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="text-right font-bold text-xl mt-3">
                        {hours}h {minutes}m
                    </div>
                </div>
            }
        </div>
    )
};

export default TodaysHistory;
