import React, { useState, useEffect, useMemo } from 'react';
import Button from "@atlaskit/button";
import numeral from "numeral";
import { subHours, subMinutes, addMinutes } from "date-fns";
import { useQueryClient } from "react-query";

import { addWorkLog } from "../../api";
import { Timer as TimerType, TimerInterval } from "../../api/types";
import { TODAYS_WORKLOGS } from "../../api/useTodaysWorklogsQuery";
import { addWorklogId, adjustFirstStartTime, pause, start, deleteTimer } from "../../redux/timers";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

interface Props {
    siteId: string;
    timer: TimerType,
    siteUrl: string;
}

const TIME_AMOUNTS = [1, 5, 10, 30, 60];

const Timer: React.FC<Props> = ({ timer, siteId, siteUrl }) => {
    const [elapsedSinceStart, setElapsedSinceStart] = useState(_totalElapsedTime(timer));
    const [timing, setTiming] = useState(timer.active);
    const [bumpUp, setBumpUp] = useState(true);
    const [showDelete, setShowDelete] = useState(false);
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (timing) {
            const interval = window.setInterval(() => {
                setElapsedSinceStart(_totalElapsedTime(timer));
            }, 200);

            return () => window.clearInterval(interval);
        }
    }, [timing, timer]);

    const toggleTimer = () => {
        if (timing) {
            setTiming(false);
            // const totalHours = elapsedTime.hours + elapsedSinceStart.hours;
            // const totalMinutes = elapsedTime.minutes + elapsedSinceStart.minutes;
            // const totalSeconds = elapsedTime.seconds + elapsedSinceStart.seconds;
            // @ts-ignore
            dispatch(pause(timer, enqueueSnackbar));
        } else {
            // @ts-ignore
            dispatch(start(timer, enqueueSnackbar));
            setTiming(true);
        }
    };

    const { hours, minutes, seconds } = useMemo(() => {
        return _elapsedTimeFromInterval(elapsedSinceStart);
    }, [elapsedSinceStart]);

    const onSubmit = () => {
        if (timer.issueKey) {
            const started = subMinutes(subHours(new Date(), hours), minutes);
            addWorkLog(siteId, timer.issueKey, started, `${hours}h ${minutes}m`)
                .then(response => {
                    console.log("addWorkLog", response);

                    //@ts-ignore
                    dispatch(addWorklogId(timer, response.data.id, enqueueSnackbar));

                    onReset();
                    return queryClient.invalidateQueries(TODAYS_WORKLOGS);
                })
        }
    };

    const bumpStartTime = (minutes: number) => {
        const timeFnc = bumpUp ? subMinutes : addMinutes;
        // TODO: Need another mutation for this
        // setStartTime(timeFnc(new Date(startTime), minutes).getTime());
        // @ts-ignore
        dispatch(adjustFirstStartTime(timer, timeFnc(new Date(timer.intervals[0].start), minutes), enqueueSnackbar));
    };

    // TODO: Need to clear the intervals too
    const onReset = () => {
        setElapsedSinceStart(0);
    };

    const onDelete = () => {
        setShowDelete(false);
        // @ts-ignore
        dispatch(deleteTimer(timer, enqueueSnackbar));
    }

    return (
        <div className="mt-12 rounded border border-solid border-gray-6 relative">
            <div className="sm:hidden flex pt-2 px-4 bg-gray-6">
                <div className="flex">
                    <div>
                        <Button onClick={() => setBumpUp(true)} isSelected={bumpUp}>
                            +
                        </Button>
                    </div>
                    <div>
                        <Button onClick={() => setBumpUp(false)} isSelected={!bumpUp}>
                            -
                        </Button>
                    </div>
                </div>
                <div className="flex-1 flex items-center justify-end pr-2 font-bold cursor-pointer" onClick={() => window.open(`${siteUrl}/browse/${timer.issueKey}`)}>
                    {timer.issueKey}
                </div>
            </div>
            <div className="flex justify-between p-2 bg-gray-6">
                <div className="hidden sm:flex">
                    <div>
                        <Button onClick={() => setBumpUp(true)} isSelected={bumpUp}>
                            +
                        </Button>
                    </div>
                    <div>
                        <Button onClick={() => setBumpUp(false)} isSelected={!bumpUp}>
                            -
                        </Button>
                    </div>
                </div>
                {TIME_AMOUNTS.map(amount => (
                    <div key={`quick-amount-${amount}`}>
                        <Button appearance="subtle" onClick={() => bumpStartTime(amount)}>
                            {bumpUp ? "+" : "-"}{amount}m
                        </Button>
                    </div>
                ))}
            </div>
            {/*<div className="flex mb-5">*/}
            {/*    {TIME_AMOUNTS.map(amount => (*/}
            {/*        <div key={`quick-amount-${amount}`} className="mr-4">*/}
            {/*            <Button appearance="subtle" onClick={() => bumpStartTime(amount)}>*/}
            {/*                {bumpUp ? "+ " : "- "}{amount}m*/}
            {/*            </Button>*/}
            {/*        </div>*/}
            {/*    ))}*/}
            {/*</div>*/}
            <div className="flex justify-center my-4">
                <div className="font-bold text-xl">
                    {numeral(hours).format("00")}h:{numeral(minutes).format("00")}m:{numeral(seconds).format("00")}s
                </div>
            </div>
            <div className="flex justify-end pb-2 pr-2">
                <div className="hidden sm:flex flex-1 items-center text-left pl-4 font-bold cursor-pointer" onClick={() => window.open(`${siteUrl}/browse/${timer.issueKey}`)}>
                    {timer.issueKey}
                </div>
                <div className="mr-4">
                    <Button onClick={onReset} appearance="subtle">
                        Reset
                    </Button>
                </div>
                <div className="mr-4">
                    <Button onClick={() => setShowDelete(true)} appearance="subtle">
                        Delete
                    </Button>
                </div>
                <div className="mr-4">
                    <Button onClick={onSubmit}>
                        Submit
                    </Button>
                </div>
                <div>
                    <Button onClick={toggleTimer} appearance={timing ? "danger" : "primary"}>
                        {timing ? "Stop" : "Start"}
                    </Button>
                </div>
            </div>
            {showDelete &&
                <div className="absolute inset-0 bg-gray-5 flex flex-col items-center justify-center rounded">
                    <div className="text-2xl font-bold">
                        Delete Timer
                    </div>
                    <div className="mt-2">
                        Are you sure you want to delete this Timer?
                    </div>
                    <div className="mt-2 flex">
                        <div className="mr-3">
                            <Button appearance="subtle" onClick={() => setShowDelete(false)}>
                                Cancel
                            </Button>
                        </div>
                        <Button appearance="danger" onClick={onDelete}>
                            Delete
                        </Button>
                    </div>
                </div>
            }
        </div>
    )
};

function _totalElapsedTime(timer: TimerType) {
    return timer ?
        timer.intervals.reduce((ret: number, interval: TimerInterval) => {
            return ret + (interval.end || new Date()).getTime() - interval.start.getTime();
        }, 0) :
        0;
}

function _elapsedTimeFromInterval(interval: number) {
    const totalSeconds = Math.floor(interval / 1000);
    const seconds = totalSeconds % 60;
    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60) % 24;
    return { minutes, hours, seconds };
}

export default Timer;
