import React, { useEffect } from 'react';
import { LoadingButton } from '@atlaskit/button';
import { useParams } from "react-router-dom";

import { loginWithToken } from "../../api";

interface Params {
    token: string
}

const Login = () => {
    const { token } = useParams<Params>();

    const login = () => {
        document.location.href = `${process.env.REACT_APP_TYMELY_URL}/auth/login`;
    };

    useEffect(() => {
        if (token) {
            loginWithToken(token);
        }
    }, [token]);

    return (
        <div className="flex justify-center mt-44">
            <LoadingButton onClick={login} isLoading={Boolean(token)}>Login to JIRA</LoadingButton>
        </div>
    )
};

export default Login;
