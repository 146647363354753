import { createSlice } from '@reduxjs/toolkit'

import {getTimers, addTimer, startTimer, pauseTimer, adjustFirstStart, addWorklog} from "../../api";

const INITIAL_STATE = {
    loading: false,
    timerMap: {},
};

const timersSlice = createSlice({
    name: 'timers',
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.loading = action.payload;
        },
        timersSuccess(state, action) {
            action.payload.forEach(timer => {
                state.timerMap[timer.id] = timer;
            })
        },
        timerDeleted(state, action) {
            delete state.timerMap[action.payload];
        }
    }
});

export const { timersSuccess, loading, timerDeleted } = timersSlice.actions;

export default timersSlice.reducer

// CUSTOM THUNK ACTIONS

export const getAllTimers = (enqueueSnackbar) => (
    (dispatch, getState) => {
        dispatch(loading(true));
        const uid = getState().login.user.uid;
        return getTimers(uid)
            .then(timers => {
                dispatch(timersSuccess(timers));
            })
            .catch(error => {
                dispatch(loading(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const add = (issueKey, enqueueSnackbar) => (
    (dispatch, getState) => {
        const uid = getState().login.user.uid;
        console.log("uid", uid, issueKey);
        return addTimer(uid, issueKey)
            .then(timer => {
                dispatch(timersSuccess([timer]));
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const start = (timer, enqueueSnackbar) => (
    (dispatch, getState) => {
        return startTimer(timer)
            .then(updatedTimer => {
                dispatch(timersSuccess([updatedTimer]));
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const pause = (timer, enqueueSnackbar) => (
    (dispatch, getState) => {
        return pauseTimer(timer)
            .then(updatedTimer => {
                dispatch(timersSuccess([updatedTimer]));
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const adjustFirstStartTime = (timer, start, enqueueSnackbar) => (
    (dispatch, getState) => {
        return adjustFirstStart(timer, start)
            .then(updatedTimer => {
                dispatch(timersSuccess([updatedTimer]));
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const addWorklogId = (timer, worklogId, enqueueSnackbar) => (
    (dispatch, getState) => {
        return addWorklog(timer, worklogId)
            .then(updatedTimer => {
                dispatch(timersSuccess([updatedTimer]));
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
)

export const deleteTimer = (timer, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.timers.delete(timer.id)
            .then(() => {
                dispatch(timerDeleted(timer.id));
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
)
