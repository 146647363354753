import { initializeApp } from "firebase/app"
import { getAuth, signInWithCustomToken, signOut } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { format } from "date-fns";
import TymelyApi, { ModularFirestoreAdapter } from "@devsontap/tymely-api";

// import FirestoreModel from "./models/utils/FirestoreModelNew";
// import FirestoreAdapter, {ModularFirestoreAdapter} from "./models/utils/FirestoreAdapter";
//import timer from "./models/timer";
import { Timer } from "./types";

export const DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSxx";

const firebaseApp = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});

const api = new TymelyApi(new ModularFirestoreAdapter());

// connectFunctionsEmulator(getFunctions(), "localhost", 5001);

export const loginWithToken = (token: string) => {
    return signInWithCustomToken(getAuth(firebaseApp), token)
        .then(_ => {
            document.location.href = "/";
            return true;
        });
};

export const logout = () => {
    return signOut(getAuth());
};

export const getJiraProjects = (siteId: string) => {
    return httpsCallable(getFunctions(), "jira-projects")({ siteId });
};

export const getActiveSprintIssues = (siteId: string, projectKey: string) => {
    return httpsCallable(getFunctions(), "jira-activeSprintIssues")({ siteId, projectKey });
};

export const getTodaysWorklogs = (siteId: string, today: string) => {
    return httpsCallable(getFunctions(), "jira-todaysWorklogs")({ siteId, today });
};

export const addWorkLog = (siteId: string, issueKey: string, started: Date, timeSpent: string) => {
    return httpsCallable(getFunctions(), "jira-addWorklog")({ siteId, issueKey, started: format(started, DATE_TIME_FORMAT), timeSpent });
};

export const getAvailableSites = () => {
    return httpsCallable(getFunctions(), "jira-availableSites")();
};

export const getTimers = (userId: string) => {
    // @ts-ignore
    return api.timers.getByUserId(userId);
}

export const addTimer = (userId: string, issueKey: string) => {
    // @ts-ignore
    return api.timers.save({
        uid: userId,
        issueKey,
    });
}

export const pauseTimer = (timer: Timer) => {
    const intervals = [...timer.intervals];
    intervals[intervals.length - 1] = {
        ...intervals[intervals.length - 1],
        end: new Date()
    };

    // @ts-ignore
    return api.timers.save({
        ...timer,
        active: false,
        intervals,
    });
}

export const startTimer = (timer: Timer) => {
    // @ts-ignore
    return api.timers.save({
        ...timer,
        active: true,
        intervals: [...timer.intervals, { start: new Date() }],
    });
}

export const addWorklog = (timer: Timer, worklogId: string) => {
    // @ts-ignore
    return api.timers.save({
        ...timer,
        active: false,
        worklogId
    });
}

export const adjustFirstStart = (timer: Timer, start: Date) => {
    const intervals = [...timer.intervals];
    intervals[0] = {
        ...intervals[0],
        start
    }

    // @ts-ignore
    return api.timers.save({
        ...timer,
        intervals,
    });
}

// function _firestoreModel(adapter: FirestoreAdapter, model: any): FirestoreModel {
//     return new FirestoreModel(
//         adapter,
//         model.COLLECTION,
//         model.SCHEMA,
//         model.QUERIES,
//         model.convertDates
//         // model.CALLABLES,
//         // model.MIXINS,
//     );
// }

export default api;
