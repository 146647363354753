import { useQuery } from "react-query";

import { getActiveSprintIssues } from "./index";

const useActiveSprintIssuesQuery = (uid: string, siteId: string, projectKey: string) =>
    useQuery(['activeSprintIssues', uid, siteId, projectKey], () => {
        return getActiveSprintIssues(siteId, projectKey).then(response => (response.data as any).issues);
    }, {
        enabled: Boolean(uid && siteId && projectKey),
    });

export default useActiveSprintIssuesQuery;