import React, {useState, useCallback, useEffect} from 'react';
import { OptionType } from "@atlaskit/select/types";
//@ts-ignore
import { getLocal, saveLocal } from "@devsontap/dot-react-common/core/utils/localStorage";

import ProjectSelect from "../_common/selects/projectSelect";
import ActiveSprintIssuesSelect, { OptionType as IssueOptionType } from "../_common/selects/activeSprintIssuesSelect";
import Timer from "../timer";
import TodaysHistory from "../history/today";
import Button from "@atlaskit/button";
import {useDispatch, useSelector} from "react-redux";
import { signOut } from "../../redux/login";
import {userSelector} from "../../redux/login/selectors";
import useSelectedSiteQuery from "../../api/useSelectedSiteQuery";
import { Timer as TimerType } from "../../api/types";
import {add, getAllTimers} from "../../redux/timers";
import {timerListSelector} from "../../redux/timers/selectors";
import { useSnackbar } from "notistack";

const KEY_PROJECT = "project";
const KEY_ISSUE = "issue";

const Home: React.FC = () => {
    const [project, setProject] = useState<null|OptionType>(getLocal(KEY_PROJECT, true));
    const [issue, setIssue] = useState<null|IssueOptionType>(getLocal(KEY_ISSUE, true));
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const uid = user?.uid;
    const {data: selectedSite} = useSelectedSiteQuery(uid);
    const timers = useSelector(timerListSelector);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (uid) {
            // @ts-ignore
            dispatch(getAllTimers(enqueueSnackbar));
        }
    }, [uid, dispatch, enqueueSnackbar]);

    const onProjectChange = useCallback((option: OptionType) => {
        setProject(option);
        saveLocal(KEY_PROJECT, option, true);
    }, []);

    const onIssueChange = useCallback((option: IssueOptionType) => {
        console.log("onIssueChange", option);
        setIssue(option);
        saveLocal(KEY_ISSUE, option, true);
    }, []);

    const onAddTimer = () => {
        // @ts-ignore
        dispatch(add(issue.value, enqueueSnackbar));
    }

    const onLogout = () => dispatch(signOut());

    console.log("selectedSite", selectedSite);

    return (
        <div className="flex flex-col items-center">
            <div className="w-full p-4 sm:w-[500px] sm:p-0">
                <div className="flex justify-end my-8">
                    <Button onClick={onLogout}>
                        Logout
                    </Button>
                </div>
                <div className="mb-6">
                    <ProjectSelect
                        siteId={selectedSite?.id}
                        siteUrl={selectedSite?.url}
                        onChange={onProjectChange}
                        value={project} />
                </div>
                {/*    TODO: Grab this from initial payload */}
                <ActiveSprintIssuesSelect
                    onChange={onIssueChange}
                    value={issue}
                    siteId={selectedSite?.id}
                    siteUrl={selectedSite?.url}
                    projectKey={project?.value?.toString()} />
                <div className="mb-8 mt-4">
                    <Button className="w-full" isSelected onClick={onAddTimer}>
                        + Add Timer
                    </Button>
                </div>
                <div>
                    {timers?.map((timer: TimerType) => (
                        <Timer key={`timer-${timer.id}`} timer={timer} siteId={selectedSite?.id} siteUrl={selectedSite?.url} />
                    ))}
                </div>
                <div>
                    <TodaysHistory siteId={selectedSite?.id} />
                </div>
            </div>
        </div>
    )
};

export default Home;
