import { createSelector } from "@reduxjs/toolkit";

const timerMapSelector = ({ timers }) => timers.timerMap;
// const timersLoadingSelector = ({ timers }) => timers.loading;

export const timerListSelector = createSelector(
    timerMapSelector,
    (map) => {
        return Object.values(map).filter(timer => !timer.worklogId);
    }
)
