import { useQuery } from "react-query";
import { format } from "date-fns";

import { getTodaysWorklogs } from "./index";

export const TODAYS_WORKLOGS = 'todaysWorklogs';

const useTodaysWorklogsQuery = (uid: string, siteId: string, options?: any) =>
    useQuery([TODAYS_WORKLOGS, uid, siteId], () => {
        if (uid && siteId) {
            return getTodaysWorklogs(siteId, format(new Date(), "yyyy-MM-dd"))
                .then(response => {
                    // const issues = (response.data as any).issues;
                    // const worklogs: any[] = [];
                    //
                    // console.log("issues", issues);
                    //
                    // // TODO: Move this logic to the server
                    // issues.forEach((issue: any) => {
                    //     issue.fields.worklog.worklogs.forEach((worklog: any) => {
                    //         const date = parse(worklog.started, DATE_TIME_FORMAT, new Date());
                    //         if (isSameDay(date, new Date()) && worklog.author.accountId === uid) {
                    //             worklogs.push({
                    //                 ...worklog,
                    //                 issue
                    //             });
                    //         }
                    //     })
                    // });
                    //
                    // worklogs.sort((w1, w2) => w1.started.localeCompare(w2.started) * -1);
                    //
                    // console.log("todays worklogs", worklogs);
                    // return worklogs;
                    console.log("worklogs", response.data);
                    return response.data;
                });
        }
        return null;
    }, options);

export default useTodaysWorklogsQuery;
