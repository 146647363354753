import React, {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { onAuthStateChanged, getAuth } from "firebase/auth";

import { userUnauthed, userAuthed, claimsSuccess } from "../../redux/login";
import { loggedInSelector } from "../../redux/login/selectors";

interface Props {
    unAuthed: any
}

/**
 * This component is intended to use ONCE in the Application
 * @param children  The component to render when the user is Authed
 * @param unAuthed  The component to render when the user is Unauthed
 */
const AuthProvider: React.FC<Props> = ({ children, unAuthed }) => {
    const loggedIn = useSelector(loggedInSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("setting onAuthStateChanged...");
        onAuthStateChanged(getAuth(), async (user) => {
            console.log("onAuthStateChanged", user);
            if (user) {
                console.log("user", user);
                dispatch(userAuthed(user));

                const token = await user.getIdTokenResult(true);
                console.log("claims", token.claims);
                dispatch(claimsSuccess(token.claims));
            } else {
                dispatch(userUnauthed());
            }
        })
    }, [dispatch]);

    return loggedIn ? children : unAuthed;
};

export default AuthProvider;