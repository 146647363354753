import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from 'react-query'

import AuthProvider from "./components/authProvider";
import useReduxStore from "./hooks/useReduxStore";
import Login from "./components/login";
import Home from "./components/home";

import './App.css';

const queryClient = new QueryClient();

function App() {
    const store = useReduxStore();

    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <Router>
                    <div className="App">
                        <AuthProvider unAuthed={
                            <Route path={['/login/:token', '*']}>
                                <Login/>
                            </Route>
                        }>
                            <Home />
                        </AuthProvider>
                    </div>
                </Router>
            </Provider>
        </QueryClientProvider>
    );
}

export default App;
