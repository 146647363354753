import { useQuery } from "react-query";

import { getAvailableSites } from "./index";

const useSelectedSiteQuery = (uid: string) =>
    useQuery(['selectedSite', uid], () => {
        return getAvailableSites().then(sites => (sites.data as any)[0]);
    }, {
        enabled: Boolean(uid)
    });

export default useSelectedSiteQuery;
