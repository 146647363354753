import React, {useMemo, useCallback} from 'react';
import Select from "@atlaskit/select";
import Spinner from "@atlaskit/spinner";
import Button from "@atlaskit/button";
import { useSelector } from "react-redux";
import Launch from "@material-ui/icons/Launch";

import useActiveSprintIssuesQuery from "../../../../api/useActiveSprintIssuesQuery";
import {userSelector} from "../../../../redux/login/selectors";

interface Props {
    siteId?: string,
    siteUrl?: string,
    projectKey: string | null | undefined,
    onChange: (option: OptionType) => void,
    value: OptionType | null
}

interface OptionLabel {
    issueKey: string,
    iconUrl: string,
    summary: string
}

export interface OptionType {
    value: string,
    label: OptionLabel
}

const ActiveSprintIssuesSelect: React.FC<Props> = ({ siteId = "", siteUrl, projectKey, onChange, value }) => {
    const user = useSelector(userSelector);
    const uid = user?.uid;
    const {isLoading, data} = useActiveSprintIssuesQuery(uid, siteId, projectKey || "");

    const getImageUrl = useCallback((url: string) => {
        return url.replace(/^.+rest/, `${siteUrl}/rest`);
    }, [siteUrl]);

    console.log("activeSprintIssues", data, value);

    const options = useMemo(() => {
        if (data) {
            return (data as any[]).map((issue: any) => ({
                label: {
                    issueKey: issue.key,
                    iconUrl: getImageUrl(issue.fields.issuetype.iconUrl),
                    summary: issue.fields.summary
                },
                value: issue.key
            }))
        }
        return [];
    }, [data, getImageUrl]);

    const getOptionLabel = (option: OptionType) => `[${option.label.issueKey}] ${option.label.summary}`;

    return (
        <div>
            <div className="text-left mb-1">
                <label htmlFor="issue" className="font-bold">Issue</label>
            </div>
            <div className="flex">
                <div className="flex-1">
                    <Select
                        inputId="issue"
                        options={options}
                        getOptionLabel={getOptionLabel}
                        components={{
                            Option: ({ children, innerProps, options }) => {
                                console.log("children", children);
                                const label = options.find(option => getOptionLabel(option) === children)?.label;
                                return (
                                    <div className="flex cursor-pointer" {...innerProps}>
                                        <div className="pt-1 mr-2">
                                            <img className="w-5 h-5" src={label?.iconUrl} alt="issue type" />
                                        </div>
                                        <div className="flex-1 text-left">
                                            <strong>{`[${label?.issueKey}]`}</strong> {`${label?.summary}`}
                                        </div>
                                    </div>
                                )
                            }
                        }}
                        value={value}
                        onChange={e => {
                            onChange(e as OptionType);
                        }} />
                </div>
                <div className="w-14">
                    {isLoading ?
                        <Spinner size="medium" />  :
                        value && siteUrl ?
                            <div className="flex ml-2">
                                <Button appearance="subtle" href={`${siteUrl}/browse/${value.value}`} target="_blank">
                                    <Launch />
                                </Button>
                            </div> :
                            null
                    }
                </div>
            </div>
        </div>
    )
};

export default ActiveSprintIssuesSelect
