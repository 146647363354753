import { combineReducers } from 'redux'

import login from './login';
import timers from './timers';

/**
 * Main Redux reducer
 */
const RootReducer = combineReducers({
    login,
    timers,
});

export default RootReducer;
