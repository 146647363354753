import { createSlice } from '@reduxjs/toolkit'
import { getLocal, saveLocal } from "@devsontap/dot-react-common/core/utils/localStorage";

import { logout } from "../../api";

const KEY_LOGGED_IN = "loggedIn";
const KEY_CLAIMS = "claims";

const INITIAL_STATE = {
    loggedIn: getLocal(KEY_LOGGED_IN, false) === "true" || false, // read from disk
    loggingIn: false,
    claims: getLocal(KEY_CLAIMS, true) || {},
    user: null
};

const loginSlice = createSlice({
    name: 'login',
    initialState: INITIAL_STATE,
    reducers: {
        userAuthed(state, action) {
            saveLocal(KEY_LOGGED_IN, true, false);
            state.user = action.payload;
            state.loggingIn = false;
            state.loggedIn = true;
        },
        claimsSuccess(state, action) {
            const claims = action.payload;
            saveLocal(KEY_CLAIMS, action.payload, true);
            state.claims = claims;
        },
        userUnauthed(state) {
            saveLocal(KEY_CLAIMS, {}, true);
            saveLocal(KEY_LOGGED_IN, false, false);
            state.loggedIn = false;
            state.claims = {};
            state.user = null;
        },
        loggingIn(state, action) {
            state.loggingIn = action.payload;
        },
    }
});

export const { userAuthed, userUnauthed, loggingIn, reportsRestrictionsSuccess, claimsSuccess } = loginSlice.actions;

export default loginSlice.reducer

// CUSTOM THUNK ACTIONS

// export const signInWithEmailPassword = (email, password, enqueueSnackbar) => (
//     (dispatch, getState, api) => {
//         dispatch(loggingIn(true));
//         return api.loginWithEmailPassword(email, password)
//             .catch(error => {
//                 dispatch(loggingIn(false));
//                 console.error(error);
//                 enqueueSnackbar(error.message, {variant: 'error'});
//             });
//     }
// );
//
// export const initiatePasswordReset = (email, showConfirmation, enqueueSnackbar) => (
//     (dispatch, getState, api) => {
//         dispatch(loggingIn(true));
//         return api.initiatePasswordReset(email)
//             .then(() => {
//                 dispatch(loggingIn(false));
//                 showConfirmation();
//             })
//             .catch(error => {
//                 dispatch(loggingIn(false));
//                 console.error(error);
//                 enqueueSnackbar(error.message, {variant: 'error'});
//             });
//     }
// );
//
export const signOut = () => (
    (dispatch, getState) => {
        return logout()
            .catch(error => {
                console.error(error);
                // enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);